import React, { useEffect, useState } from 'react';
import './css/Dashboard.css';
import Card from '../components/Card';
import { useNavigate } from 'react-router-dom';
import instance from '../Utils/api';

const Dashboard = () => {
    const navigate = useNavigate();
    const [dashboarddata, setDashboardData] = useState([]);



    const fetchDashboardData = async () => {
        try {
            const response = await instance.get("/getDashboardCount");
            setDashboardData(response.data);
        } catch (error) {
            console.log(error);

        }
    }

    useEffect(() => {
        fetchDashboardData();
    }, [])
    console.log(dashboarddata);
    
    return (
        <div className='dashboard'>
            <h1>Welcome to <b>Siri Samrudhi Gold Palace.</b></h1>
            <div className='dashboard_cards' >
                <Card title={'Total Users'} count={dashboarddata?.users} onclick={() => { navigate('/UsersList') }} />
                {/* <Card title={'Total Leads'} count={feedbackdata.length} onclick={() => { navigate('/LeadsList') }} /> */}
                {/* <Card title={'Total Templates'} count={templateList.length} onclick={() => { navigate('/Templates') }} /> */}
                {/* <Card title={'Total Agents'} count={agents.length} onclick={() => { navigate('/AgentsList') }} /> */}
            </div>
        </div>
    )
}

export default Dashboard
