import { Stack } from '@mui/material';
import React, { useState } from 'react';
import Template_Card from '../components/Template_Card';
import gold from '../Assets/gold1.jpg';  // Example image
import instance from '../Utils/api';

const NewArrivals = () => {
    const [data, setData] = useState();
    // const [update, setUpdate] = useState(false);

    const getproductsData = async () => {
        try {
            const response = await instance.get('/getProduct');
            console.log(response.data);
            setData(response.data.products)
        } catch (error) {
            console.log(error);
        }
    }
    useState(() => {
        getproductsData();
    }, [])
    // useState(() => {
    //     if (update === true) {
    //         getproductsData();
    //         setUpdate(false);
    //     }
    // }, [update])
    // console.log(update);

    return (
        <Stack direction="row" spacing={2} justifyContent="center" flexWrap="wrap">
            {data?.map((data) => (
                <Template_Card key={data._id} data={data} setUpdate={getproductsData} />
            ))}
        </Stack>
    );
};

export default NewArrivals;
