import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import instance from '../Utils/api';
import Pagination from '../components/Pagination';
import SearchBar from '../components/SearchBar';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import { Button, Stack } from '@mui/material';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#E58638",
        color: theme.palette.common.white,
        fontSize: '16px',
        fontWeight: 600,
        fontFamily: "Poppins",

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontFamily: "Poppins",
        color: "#828282"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Feedback = () => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [filteredData, setFilteredData] = useState(data);
    const navigate = useNavigate();



    const rowsPerPage = 10;
    const dataToShow = filteredData.slice((page - 1) * rowsPerPage, page * rowsPerPage);
    console.log(dataToShow);
    const fetchData = async () => {
        try {
            const response = await instance.get("/UsersList");
            // console.log(response.data);
            setData(response.data.users)
            setFilteredData(response.data.users)
        } catch (error) {
            console.log(error);

        }
    }

    const handleDownload = async () => {
        try {
            // Send GET request to download the Excel file from the backend
            const response = await instance.get('/getUsersExcelDownload?download=true', {
                responseType: 'blob', // Important for downloading files
            });

            // Create a Blob from the Excel file response
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Create a link element to trigger the download
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob); // Create an object URL from the Blob
            link.download = 'users-list.xlsx'; // Set the file name
            document.body.appendChild(link); // Append the link to the document
            link.click(); // Programmatically click the link to start the download
            document.body.removeChild(link); // Remove the link from the document after downloading
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [])
    return (
        <div>
            <div style={{ width: "100%", padding: "15px 0px 15px 0px", display: 'flex', justifyContent: 'space-between' }}>
                <SearchBar data={data} setFilteredData={setFilteredData} filterKey={'UserName'} />
                <Stack direction="row" spacing={2}>
                    <Pagination page={page} setPage={setPage} count={filteredData.length} rowsPerPage={rowsPerPage} />
                    <Button sx={{ backgroundColor: '#E58638', color: 'white' }} onClick={handleDownload}>Download Users Excel</Button>
                </Stack>
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 600 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Username</StyledTableCell>
                            <StyledTableCell>Phone Number</StyledTableCell>
                            <StyledTableCell>Date</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataToShow.map((row) => (
                            <StyledTableRow key={row.name}>
                                <StyledTableCell component="th" scope="row">{row.UserName}</StyledTableCell>
                                <StyledTableCell >{row.MobileNumber}</StyledTableCell>
                                <StyledTableCell >{new Date(row.createdAt).toLocaleDateString()}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default Feedback
