import axios from 'axios';
import Cookies from 'js-cookie'

const Token = localStorage.getItem('token');
// const Token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NzBlNDE1NWFjZTRmNDFiNjliYjIzNDEiLCJpYXQiOjE3Mjg5ODc4ODd9.qdtiUzjj6ruubc7G8AekHPyVbxd9jXgDUM03jh03XFs";


// const api = process.env.REACT_APP_API_URL;
// const api = 'http://localhost:4050/api';
const api = 'https://backend.sirisamruddhigold.in/api';

const createAxiosInstance = (token) => {
  return axios.create({
    baseURL: `${api}`,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${Token}`,
      'x-token': Token
    },
  });
};

export const instance = createAxiosInstance(Token);

export const updateToken = (newToken) => {
  // Create a new axios instance with the updated token
  instance.defaults.headers['Authorization'] = `Bearer ${newToken.trim()}`;
  instance.defaults.headers['x-token'] = newToken
};

export default instance;