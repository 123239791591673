
import './App.css';
// import RazerpayIntegration from './components/RazerpayIntegration';
import { Navigate, Route, Router, Routes, useNavigate } from 'react-router-dom';
// import Payment_status from './components/Payment_status';
import Feedback from './pages/Feedback';
import Dashboard from './pages/Dashboard';
import Home from './components/Home';
import Login from './pages/Login';
import { useState } from 'react';
import Messages from './pages/Messages';
import NewArrivels from './pages/NewArrivels';


function App() {
  const [triggerRefresh, setTriggerRefresh] = useState();
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  console.log(token);

  let isAuth = token !== null;
  console.log(isAuth);


  return (
    <Routes>
      {isAuth === true ?
        <Route path='/' element={<Home />}>
          <Route index element={<Dashboard />} />
          <Route path='/Dashboard' element={<Dashboard />} />
          <Route path='/UsersList' element={<Feedback />} />
          <Route path='/NewArrivels' element={<NewArrivels />} />
          <Route path='/Messages' element={<Messages />} />
          <Route path='/*' element={<Navigate to={"/Dashboard"} />} />
          <Route path='/Messages' element={<Messages />} />
        </Route> :
        <Route path='/login' element={<Login setTriggerRefresh={setTriggerRefresh} />} />}
      <Route path='/*' element={<Navigate to={"/login"} />} />

    </Routes>
  );
}

export default App;
