import React, { useState } from 'react';
import { Stack, Typography, Dialog, DialogActions, DialogContent, DialogTitle, TextField, IconButton, Button } from '@mui/material';
import { Close } from '@mui/icons-material'; // Import Close icon from MUI
import './styles/Template_Card.css';  // Make sure this is your CSS file path
import instance from '../Utils/api';
import { toast } from 'react-toastify';

const Template_Card = ({ data, setUpdate }) => {
    const [openModal, setOpenModal] = useState(false);
    const [description, setDescription] = useState(data.description);
    const [image, setImage] = useState(null);
    const [imagedata, setImagedata] = useState(null);

    const handleEdit = () => {
        setOpenModal(true); // Open the modal when clicking the edit button
    };

    const handleCloseModal = () => {
        setOpenModal(false); // Close the modal
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value); // Update description as the user types
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0]; // Get the selected file
        if (file) {
            setImagedata(file);
            setImage(URL.createObjectURL(file)); // Preview the uploaded image
        }
    };

    const handleSubmit = async () => {
        try {
            const formdata = new FormData();
            formdata.append('id', data?._id);
            formdata.append('image', imagedata);
            formdata.append('description', description);
            const response = await instance.put('/editProduct', formdata);
            if (response.data.success === true) {
                toast.success(response.data.message);// Display success message
                setOpenModal(false); // Close the modal
                setUpdate();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleCancel = () => {
        setOpenModal(false); // Close the modal without saving changes
    };

    return (
        <>
            <Stack className="message-card">
                <div className="header-image">
                    <img
                        src={`https://backend.sirisamruddhigold.in/public/${data.image}`}
                        // src={`http://localhost:4050/public/${data.image}`}
                        alt={`Product ${data._id}`}
                        className="product-image"
                    />
                </div>
                <div className="message-body">
                    <Typography variant="body2" className="message-text">
                        {data.description}
                    </Typography>
                </div>
                <button className="edit-button" onClick={handleEdit}>
                    Edit
                </button>
            </Stack>

            {/* Modal for editing product */}
            <Dialog open={openModal} onClose={handleCloseModal} maxWidth="sm" fullWidth sx={{ overflowY: 'hidden' }}>
                <DialogTitle>
                    Edit Product
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleCloseModal}
                        aria-label="close"
                        sx={{
                            position: 'absolute',
                            right: 25,
                            top: 10,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        {/* Image Upload */}
                        <div>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                id="image-upload"
                                style={{ display: 'none' }}
                            />
                            <label htmlFor="image-upload">
                                <Button variant="contained" component="span" fullWidth>
                                    Upload Image
                                </Button>
                            </label>
                            {image && (
                                <div className="dialog-image-preview">
                                    <img
                                        src={image}
                                        alt="Preview"
                                    />
                                </div>
                            )}
                        </div>

                        {/* Description Text Field */}
                        <TextField
                            label="Description"
                            multiline
                            rows={4}
                            value={description}
                            onChange={handleDescriptionChange}
                            fullWidth
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Template_Card;
